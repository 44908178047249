import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/redirect',
      component: () => import('@/layouts/Default'),
      hidden: true,
      children: [
        {
          path: '/redirect/:path*',
          component: () => import('@/pages/redirect/index')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/layouts/Default'),
      children: [
        {
          path: '',
          component: () => import('@/pages/Home/Home'),
          name: 'Home',
          meta: {
            pai: 'Home',
            title: 'admiN - Home',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: true,
                url: ''
              }
            ],
            noCache: true
          }
        },

        {
          path: 'inicio/atualizacoes',
          component: () => import('@/pages/Inicio/Atualizacoes'),
          name: 'Atualizacoes',
          meta: {
            path: '/inicio/atualizacoes',
            pai: 'Início',
            title: 'admiN - Atualizações',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Atualizações',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/atualizacoes/novo',
          component: () => import('@/pages/Inicio/AtualizacoesForm'),
          name: 'AtualizacoesFormNovo',
          meta: {
            path: '/inicio/atualizacoes',
            pai: 'Início',
            title: 'admiN - Atualizações',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Atualizações',
                class: '',
                active: false,
                url: '/inicio/atualizacoes'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/atualizacoes/:id',
          component: () => import('@/pages/Inicio/AtualizacoesForm'),
          name: 'AtualizacoesFormId',
          meta: {
            path: '/inicio/atualizacoes',
            pai: 'Início',
            title: 'admiN - Atualizações',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Atualizações',
                class: '',
                active: false,
                url: '/inicio/atualizacoes'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/chamados',
          component: () => import('@/pages/Inicio/Chamados'),
          name: 'Chamados',
          meta: {
            path: '/inicio/chamados',
            pai: 'Início',
            title: 'admiN - Chamados',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Chamados',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/chamados/novo',
          component: () => import('@/pages/Inicio/ChamadosForm'),
          name: 'ChamadosFormNovo',
          meta: {
            path: '/inicio/chamados',
            pai: 'Início',
            title: 'admiN - Chamados',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Chamados',
                class: '',
                active: false,
                url: '/inicio/chamados'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/chamados/:id',
          component: () => import('@/pages/Inicio/ChamadosForm'),
          name: 'ChamadosFormId',
          meta: {
            path: '/inicio/chamados',
            pai: 'Início',
            title: 'admiN - Chamados',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Chamados',
                class: '',
                active: false,
                url: '/inicio/chamados'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'inicio/minha-conta',
          component: () => import('@/pages/Inicio/MinhaConta'),
          name: 'MinhaConta',
          meta: {
            path: '/inicio/minha-conta',
            pai: 'Início',
            title: 'admiN - Minha Conta',
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Minha Conta',
                class: '',
                active: true,
                url: false
              }
            ],
            noCache: true
          }
        },
        {
          path: 'admin/usuarios',
          component: () => import('@/pages/Admin/Usuarios'),
          name: 'Usuarios',
          meta: {
            path: '/admin/usuarios',
            pai: 'Admin',
            title: 'admiN - Usuários',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Usuários',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/usuarios/novo',
          component: () => import('@/pages/Admin/UsuariosForm'),
          name: 'UsuariosFormNovo',
          meta: {
            path: '/admin/usuarios',
            pai: 'Admin',
            title: 'admiN - Usuários',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Usuários',
                class: '',
                active: false,
                url: '/admin/usuarios'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/usuarios/:id',
          component: () => import('@/pages/Admin/UsuariosForm'),
          name: 'UsuariosFormId',
          meta: {
            path: '/admin/usuarios',
            pai: 'Admin',
            title: 'admiN - Usuários',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Usuários',
                class: '',
                active: false,
                url: '/admin/usuarios'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'admin/grupo-de-acesso',
          component: () => import('@/pages/Admin/GrupoDeAcesso'),
          name: 'GrupoDeAcesso',
          meta: {
            path: '/admin/grupo-de-acesso',
            pai: 'Admin',
            title: 'admiN - Grupo de Acesso',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Grupo de Acesso',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/grupo-de-acesso/novo',
          component: () => import('@/pages/Admin/GrupoDeAcessoForm'),
          name: 'GrupoDeAcessoFormNovo',
          meta: {
            path: '/admin/grupo-de-acesso',
            pai: 'Admin',
            title: 'admiN - Grupo de Acesso',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Grupo de Acesso',
                class: '',
                active: false,
                url: '/admin/grupo-de-acesso'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/grupo-de-acesso/:id',
          component: () => import('@/pages/Admin/GrupoDeAcessoForm'),
          name: 'GrupoDeAcessoFormId',
          meta: {
            path: '/admin/grupo-de-acesso',
            pai: 'Admin',
            title: 'admiN - Grupo de Acesso',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Grupo de Acesso',
                class: '',
                active: false,
                url: '/admin/grupo-de-acesso'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/missoes',
          component: () => import('@/pages/Sistema/Missoes'),
          name: 'Missoes',
          meta: {
            path: '/sistema/missoes',
            pai: 'Sistema',
            title: 'admiN - Missões',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Missoes',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/Missoes/novo',
          component: () => import('@/pages/Sistema/MissoesForm'),
          name: 'MissoesFormNovo',
          meta: {
            path: '/sistema/missoes',
            pai: 'Sistema',
            title: 'admiN - Missoes',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Missoes',
                class: '',
                active: false,
                url: '/sistema/Missoes'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/Missoes/:id',
          component: () => import('@/pages/Sistema/MissoesForm'),
          name: 'MissoesFormId',
          meta: {
            path: '/sistema/missoes',
            pai: 'Sistema',
            title: 'admiN - Missoes',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Missoes',
                class: '',
                active: false,
                url: '/Sistema/Missoes'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'sistema/missoesOld',
          component: () => import('@/pages/Sistema/MissoesOld'),
          name: 'MissoesOld',
          meta: {
            path: '/sistema/missoesOld',
            pai: 'Sistema',
            title: 'admiN - Missões',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'MissoesOld',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/MissoesOld/novo',
          component: () => import('@/pages/Sistema/MissoesOldForm'),
          name: 'MissoesOldFormNovo',
          meta: {
            path: '/sistema/missoesOld',
            pai: 'Sistema',
            title: 'admiN - MissoesOld',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'MissoesOld',
                class: '',
                active: false,
                url: '/sistema/MissoesOld'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/MissoesOld/:id',
          component: () => import('@/pages/Sistema/MissoesOldForm'),
          name: 'MissoesOldFormId',
          meta: {
            path: '/sistema/missoesOld',
            pai: 'Sistema',
            title: 'admiN - MissoesOld',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'MissoesOld',
                class: '',
                active: false,
                url: '/Sistema/MissoesOld'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'sistema/regioes',
          component: () => import('@/pages/Sistema/Regioes'),
          name: 'regioes',
          meta: {
            path: '/sistema/regioes',
            pai: 'Sistema',
            title: 'admiN - Regiões',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Regiões',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/regioes/novo',
          component: () => import('@/pages/Sistema/RegioesForm'),
          name: 'regioesFormNovo',
          meta: {
            path: '/sistema/regioes',
            pai: 'Sistema',
            title: 'admiN - Regiões',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Regiões',
                class: '',
                active: false,
                url: '/sistema/regioes'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/regioes/:id',
          component: () => import('@/pages/Sistema/RegioesForm'),
          name: 'regioesFormId',
          meta: {
            path: '/sistema/regioes',
            pai: 'Sistema',
            title: 'admiN - Regiões',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Regiões',
                class: '',
                active: false,
                url: '/Sistema/regioes'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'sistema/mapas',
          component: () => import('@/pages/Sistema/Mapas'),
          name: 'mapas',
          meta: {
            path: '/sistema/mapas',
            pai: 'Sistema',
            title: 'admiN - Mapas',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Mapas',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/mapas/novo',
          component: () => import('@/pages/Sistema/MapasForm'),
          name: 'mapasFormNovo',
          meta: {
            path: '/sistema/mapas',
            pai: 'Sistema',
            title: 'admiN - Mapas',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Mapas',
                class: '',
                active: false,
                url: '/sistema/mapas'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/mapas/:id',
          component: () => import('@/pages/Sistema/MapasForm'),
          name: 'mapasFormId',
          meta: {
            path: '/sistema/mapas',
            pai: 'Sistema',
            title: 'admiN - Mapas',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Mapas',
                class: '',
                active: false,
                url: '/Sistema/mapas'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'sistema/regex',
          component: () => import('@/pages/Sistema/Regex'),
          name: 'Regex',
          meta: {
            path: '/sistema/regex',
            pai: 'Sistema',
            title: 'admiN - Regex',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Regex',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/regex/novo',
          component: () => import('@/pages/Sistema/RegexForm'),
          name: 'RegexFormNovo',
          meta: {
            path: '/sistema/regex',
            pai: 'Sistema',
            title: 'admiN - Regex',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Regex',
                class: '',
                active: false,
                url: '/sistema/regex'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/regex/:id',
          component: () => import('@/pages/Sistema/RegexForm'),
          name: 'RegexFormId',
          meta: {
            path: '/sistema/regex',
            pai: 'Sistema',
            title: 'admiN - Regex',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Regex',
                class: '',
                active: false,
                url: '/Sistema/regex'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },

        {
          path: 'sistema/tipagem',
          component: () => import('@/pages/Sistema/Tipagem'),
          name: 'Tipagem',
          meta: {
            path: '/sistema/tipagem',
            pai: 'Sistema',
            title: 'admiN - Tipagem',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Tipagem',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/tipagem/novo',
          component: () => import('@/pages/Sistema/TipagemForm'),
          name: 'TipagemFormNovo',
          meta: {
            path: '/sistema/tipagem',
            pai: 'Sistema',
            title: 'admiN - Tipagem',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Tipagem',
                class: '',
                active: false,
                url: '/sistema/tipagem'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/tipagem/:id',
          component: () => import('@/pages/Sistema/TipagemForm'),
          name: 'TipagemFormId',
          meta: {
            path: '/sistema/tipagem',
            pai: 'Sistema',
            title: 'admiN - Tipagem',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Tipagem',
                class: '',
                active: false,
                url: '/Sistema/Tipagem'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/eventos',
          component: () => import('@/pages/Sistema/Eventos'),
          name: 'Eventos',
          meta: {
            path: '/sistema/eventos',
            pai: 'Sistema',
            title: 'admiN - Eventos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Eventos',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/eventos/novo',
          component: () => import('@/pages/Sistema/EventosForm'),
          name: 'EventosFormNovo',
          meta: {
            path: '/sistema/eventos',
            pai: 'Sistema',
            title: 'admiN - Eventos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Eventos',
                class: '',
                active: false,
                url: '/sistema/eventos'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/eventos/:id',
          component: () => import('@/pages/Sistema/EventosForm'),
          name: 'EventosFormId',
          meta: {
            path: '/sistema/eventos',
            pai: 'Sistema',
            title: 'admiN - Eventos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Eventos',
                class: '',
                active: false,
                url: '/Sistema/Eventos'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/status',
          component: () => import('@/pages/Admin/Status'),
          name: 'Status',
          meta: {
            path: '/admin/status',
            pai: 'Admin',
            title: 'admiN - Status',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Status',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/status/novo',
          component: () => import('@/pages/Admin/StatusForm'),
          name: 'StatusFormNovo',
          meta: {
            path: '/admin/status',
            pai: 'Admin',
            title: 'admiN - Status',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Status',
                class: '',
                active: false,
                url: '/admin/status'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'admin/status/:id',
          component: () => import('@/pages/Admin/StatusForm'),
          name: 'StatusFormId',
          meta: {
            path: '/admin/status',
            pai: 'Admin',
            title: 'admiN - Status',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Status',
                class: '',
                active: false,
                url: '/admin/status'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/videos',
          component: () => import('@/pages/Sistema/Videos'),
          name: 'Vídeos',
          meta: {
            path: '/sistema/videos',
            pai: 'Sistema',
            title: 'admiN - Vídeos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Vídeos',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/videos/novo',
          component: () => import('@/pages/Sistema/VideosForm'),
          name: 'VídeosFormNovo',
          meta: {
            path: '/sistema/videos',
            pai: 'Sistema',
            title: 'Sistema - Vídeos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Vídeos',
                class: '',
                active: false,
                url: '/sistema/videos'
              },
              {
                pagina: 'Novo',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'sistema/videos/:id',
          component: () => import('@/pages/Sistema/VideosForm'),
          name: 'VídeosFormId',
          meta: {
            path: '/sistema/videos',
            pai: 'Sistema',
            title: 'admiN - Vídeos',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Vídeos',
                class: '',
                active: false,
                url: '/sistema/videos'
              },
              {
                pagina: 'Editar',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        },
        {
          path: 'logs/logs',
          component: () => import('@/pages/Logs/Logs'),
          name: 'Logs',
          meta: {
            path: '/logs/logs',
            pai: 'Logs',
            title: 'admiN - Logs',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Logs',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        }
      ]
    },
    {
      path: '/acessar',
      component: () => import('@/layouts/Login'),
      children: [
        {
          path: '',
          component: () => import('@/pages/Login/Acessar'),
          name: 'Acessar',
          meta: {
            title: 'admiN - Acessar',
            noCache: true
          }
        }
      ]
    },
    {
      path: '/imprimir',
      component: () => import('@/layouts/Imprimir'),
      children: [
        {
          path: 'recibo/:id',
          component: () => import('@/pages/Imprimir/Recibo'),
          name: 'Recibo',
          meta: {
            title: 'admiN - Acessar',
            noCache: true
          }
        },
        {
          path: 'contrato/:id',
          component: () => import('@/pages/Imprimir/Contrato'),
          name: 'Contrato',
          meta: {
            title: 'admiN - Acessar',
            noCache: true
          }
        }
      ]
    },
    {
      path: '*',
      component: () => import('@/layouts/Default'),
      children: [
        {
          path: '',
          component: () => import('@/pages/Erro/404'),
          name: '404',
          meta: {
            pai: 'Admin',
            title: 'admiN - Erro',
            noCache: true,
            navigation: [
              {
                pagina: 'Home',
                class: 'ace-icon fa fa-home home-icon',
                active: false,
                url: '/'
              },
              {
                pagina: 'Erro 404',
                class: '',
                active: true,
                url: false
              }
            ]
          }
        }
      ]
    }
  ]
})
